import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { InputAlert } from '../global/input-alert/input-alert';

import { Force, Unit, UnitTemplate, Faction, ValidationError, UnitOption } from './models';

export abstract class ForceUtils {
    abstract gameId: string;
    protected translateService?: TranslateService;
    protected inputAlert?: InputAlert;

    getForceCost(force: Force): number {
        // This returns an Observable<number> because some games rely
        // on the current settings to calculate the cost of the force.
        const unitCosts = force.units.map((u) => this.getUnitCost(u, force));

        return unitCosts.reduce((total, cost) => {
            console.log('!!!!! Total cost', total, cost);
            return total + cost;
        }, 0);
    }

    attachCostToForce(f: Force) {
        return f.error
            ? f
            : {
                  ...f,
                  cost: this.getForceCost(f)
              };
    }

    getDefaultForce(forceType?: Faction): Partial<Force> {
        return { faction: forceType.key, gameId: this.gameId, name: '', units: [], cost: 0 };
    }

    abstract validateForce(force: Force): ValidationError[];

    getForceFromServer(forceId: string): Observable<Force> {
        throw new Error('getForceFromServer not implemented');
    }

    // renamePlatoon(force: Force, platoonIndex: number): Promise<Force> {
    //     return new Promise((resolve, _reject) => {
    //         snapshot(
    //             combineLatest([
    //                 this.translateService.get('FORCES.DIALOGS.PLATOON_NAME.TITLE'),
    //                 this.translateService.get('FORCES.DIALOGS.PLATOON_NAME.MESSAGE')
    //             ]),
    //             ([title, message]) => {
    //                 this.inputAlert.show(title, message, force.platoons[platoonIndex] || '').then((val) => {
    //                     if (!val) {
    //                         return;
    //                     }
    //                     const platoons = [...force.platoons];
    //                     platoons[platoonIndex] = val;
    //                     force.platoons = platoons;
    //                     resolve(force);
    //                 });
    //             }
    //         );
    //     });
    // }

    abstract getUnitCost(unit: Unit | UnitTemplate, force?: Force, dataBucket?: any): number;
    abstract createUnitFromTemplate(unitTemplate: UnitTemplate, dataBucket?: any): Unit;
    abstract processForce(force: Force): Promise<Force>;
    abstract canUnitReceiveUpgrade(
        unit: Unit,
        force: Force,
        upgrade: UnitOption
    ): { canReceiveUpgrade: boolean; errorMessage?: { headerKey: string; messageKey: string } };

    preProcessForce(force: Force, unitTemplates: UnitTemplate[], forceId: string): Force {
        const units = force.units.map(this.attachUnitTemplate(unitTemplates)).filter((u) => !!u);
        return {
            ...structuredClone(force),
            validationErrors: [],
            units,
            selected: force.id === forceId
        };
    }

    postProcessForce(force: Force) {
        let f = this.attachCostToForce(force);
        if (f.error) {
            return f; // Don't bother with validation if the force isn't even loading}
        }
        const validationErrors = this.validateForce(f);
        f.validationErrors = validationErrors;

        if (f.platoons) {
            // Some games (e.g. Bolt Action) subdivide forces, which we call platoons
            f.platoons = f.platoons.map((p) => {
                return {
                    ...p,
                    validationErrors: validationErrors.filter((e) => e.platoonId === p.id)
                };
            });
        }

        return f;
    }

    attachUnitTemplate(unitTemplates: UnitTemplate[]) {
        return (unit) => {
            const unitTemplate = unitTemplates.find((u) => u._id === unit.unitTemplateId);
            if (!unitTemplate) {
                return null;
            }

            return {
                ...unit,
                unitTemplate
            };
        };
    }
}
