import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription, Observable, map, take, combineLatest, distinctUntilChanged, shareReplay, switchMap, from, tap } from 'rxjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { markFormTouched, snapshot } from '../global/utils';
import { ArmyBuilderConfig, HttpClientWithInFlightCache, UserService } from '../global';

import { SupportService } from './support.service';
import { updateIssue } from './support.actions';
import { selectIssueById } from './support.selectors';
import { SupportIssue, SupportMode } from './model';
import { Force, ForceService } from '../forces';
import { ToastService } from '../global/toast/toast';

dayjs.extend(relativeTime);

@Component({
    selector: 'abs-issue-detail',
    template: `<abs-modal-layout>
        <!-- (close)="this.onDismiss(this.issue)" -->
        <ion-spinner *ngIf="loading"></ion-spinner>
        <ng-container *ngIf="issue$ | async as issue">
            <p><a href="#" (click)="toggleRaw($event)">Toggle Raw Data</a></p>
            <ng-container *ngIf="!showRaw">
                <p>
                    Status: <abs-issue-status [status]="issue.status"></abs-issue-status>
                    <strong>{{ issue.status }}</strong>
                </p>
                <p>
                    Page: <a [href]="issue.url" target="_blank">{{ issue.url }}</a>
                </p>
                <p>
                    Reported <strong><abs-relative-date [date]="issue.createdAt"></abs-relative-date></strong> by
                    <strong>{{ issue.userData.user.name }}</strong>
                </p>

                <div class="buttons" *ngIf="mode !== 'user'">
                    <ion-button (click)="viewUserInRC()">View User in RevenueCat</ion-button>
                    <ion-button *ngIf="issue.forces?.length > 0" (click)="copyUserForces(issue)"
                        >Copy {{ issue.forces?.length }} User Forces</ion-button
                    >
                </div>
                <pre>{{ issue.description }}</pre>
            </ng-container>

            <div *ngIf="showRaw">
                <h2>Reporter</h2>
                <pre>{{ reporter$ | async | json }}</pre>
                <h2>Issue</h2>
                <pre>{{ issue | json }}</pre>
            </div>

            <form *ngIf="issueForm" [formGroup]="issueForm" (ngSubmit)="onSubmit()">
                <div *ngIf="mode !== 'user'">
                    <ion-label>Status:</ion-label>
                    <ion-select formControlName="issueStatus">
                        <ion-select-option [value]="'New'">New</ion-select-option>
                        <ion-select-option [value]="'In progress'">In progress</ion-select-option>
                        <ion-select-option [value]="'Resolved'">Resolved</ion-select-option>
                        <ion-select-option [value]="'Rejected'">Rejected</ion-select-option>
                        <ion-select-option [value]="'Waiting for customer'">Waiting for customer</ion-select-option>
                        <ion-select-option [value]="'Customer responded'">Customer responded</ion-select-option>
                    </ion-select>
                </div>
                <div>
                    <ion-label>Comment:</ion-label>
                    <ion-textarea formControlName="comment"></ion-textarea>
                </div>
                <ion-button [disabled]="!issueForm.valid || issueForm.pristine" type="submit">{{
                    mode === 'user' ? 'Comment' : 'Update Issue'
                }}</ion-button>
            </form>

            <ng-container *ngIf="!showRaw">
                <h2>Updates:</h2>
                <ng-template #updateTemplate let-update="item">
                    <div class="issueUpdate" *ngIf="update.description">
                        <p>
                            Updated
                            <strong><abs-relative-date [date]="update.updateDate"></abs-relative-date></strong> by
                            <strong>{{ update.updatedBy }}</strong>
                        </p>
                        <p *ngIf="update.issueStatus">New status: {{ update.issueStatus }}</p>
                        <pre>{{ update.description }}</pre>
                    </div>
                    <div *ngIf="!update.description">
                        <strong>{{ update.updatedBy }}</strong> set the ticket status to <strong>{{ update.issueStatus }}</strong>
                    </div>
                </ng-template>
                <abs-list [items]="issue.updates" [template]="updateTemplate"></abs-list>
            </ng-container>
        </ng-container>
    </abs-modal-layout>`,
    styles: [
        `
            pre {
                max-height: 300px;
                overflow: auto;
                border: 1px solid #e0e0e0;
                padding: 10px;
                width: 100%;
                white-space: break-spaces;
            }

            .issueUpdate {
                width: 100%;
                padding: 12px 0;
            }

            .issueUpdate p,
            .issueUpdate pre {
                margin: 0;
            }
        `
    ]
})
export class IssueDetailComponent implements OnInit, OnDestroy {
    @Input()
    issueId: string = null;

    issue$: Observable<SupportIssue>;
    userEmail$ = this.userService.login$.pipe(
        map((l) => l.user.email),
        distinctUntilChanged()
    );

    reporter$: Observable<any>;

    @Input()
    onDismiss: (res: any) => void = (_) => {};

    @Input()
    mode: SupportMode = 'admin';

    issueFormSub: Subscription;
    issueForm: UntypedFormGroup;
    showRaw = false;
    loading = false;

    constructor(
        private config: ArmyBuilderConfig,
        private fb: UntypedFormBuilder,
        private supportService: SupportService,
        private store: Store,
        private httpClient: HttpClientWithInFlightCache,
        private userService: UserService,
        private forceService: ForceService,
        private toast: ToastService
    ) {}
    async ngOnInit() {
        this.loading = true;
        this.loadIssue();

        this.reporter$ = this.issue$.pipe(
            map((issue) => issue.userData.user.email),
            distinctUntilChanged(),
            switchMap((email) => from(this.userService.lookupUpser(email))),
            shareReplay(1)
        );

        combineLatest([this.userEmail$, this.issue$])
            .pipe(take(1))
            .subscribe(([userEmail, issue]) => {
                if (userEmail === issue.reporterEmail) {
                    this.supportService.markIssueRead(issue._id);
                }
            });

        this.issueFormSub = this.issue$.subscribe((issue) => {
            if (!issue) {
                return;
            }

            const comment = [null];
            if (this.mode !== 'admin') {
                comment.push(Validators.required);
            }

            this.issueForm = this.fb.group({
                comment,
                issueStatus: [issue.status, Validators.required]
            });
        });
    }

    loadIssue() {
        this.issue$ = this.supportService.getIssueById(this.issueId).pipe(
            tap(() => (this.loading = false)),
            map((issue) => ({
                ...issue,
                updates: [...issue.updates].sort((a, b) => new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime())
            }))
        );
    }

    onSubmit() {
        markFormTouched(this.issueForm);

        if (this.issueForm.valid) {
            this.supportService.updateIssue(this.issueId, this.issueForm.getRawValue(), this.mode).subscribe((res) => {
                // this.issue = { ...res, updates: res.updates.sort((a, b) => b.date - a.date) };
                this.loadIssue();
                this.store.dispatch(updateIssue(res));
                this.issueForm.reset();
            });
        }
    }

    toggleRaw(e) {
        e.preventDefault();
        this.showRaw = !this.showRaw;
    }

    ngOnDestroy() {
        if (this.issueFormSub) {
            this.issueFormSub.unsubscribe();
        }
    }

    async viewUserInRC() {
        snapshot(this.reporter$, (reporter) => {
            if (reporter?._id) {
                // TODO: update the project id in this URL based on the app
                let rcUrl = 'https://app.revenuecat.com/customers/9c52d85c/' + reporter._id;
                window.open(rcUrl, '_blank');
            }
        });
    }

    copyUserForces(issue: SupportIssue) {
        for (let f of issue.forces) {
            this.forceService.copyForce({ ...f, impersonated: true, originalUserEmail: issue.reporterEmail });
        }

        this.toast.showToast('Success', `Copied ${issue.forces.length} forces to state`, { color: 'success', duration: 3000 });
    }
}
