import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { BaseFormControl } from '../base-control';
import { FormTextField, FormNumberField, FormTranslationField } from '../form-field';

@Component({
    selector: 'abs-textbox',
    template: `
        <div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <div class="form-group" *ngIf="!field.multi">
                <label *ngIf="!field.hideLabel"
                    >{{ field.label || (field.name | formLabel) }}
                    <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon
                ></label>
                <ion-input
                    *ngIf="!field.textarea"
                    [formControlName]="field.name"
                    [type]="field.type || 'textbox'"
                    [disabled]="field.readOnly"
                    (change)="updateFormValue($event)"
                    (keydown.Enter)="onEnter($event)"
                ></ion-input>
                <ion-textarea *ngIf="field.textarea" auto-grow [formControlName]="field.name" [disabled]="field.readOnly"></ion-textarea>
            </div>

            <div class="form-group" *ngIf="field.multi" [formArrayName]="field.name">
                <label *ngIf="!field.hideLabel"
                    >{{ field.label || (field.name | formLabel) }}
                    <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon
                ></label>
                <div *ngFor="let item of $any(parentFormGroup.controls[this.field.name]).controls; let i = index" class="horizontal">
                    <ion-input
                        *ngIf="!field.textarea"
                        [formControlName]="i"
                        [type]="field.type || 'textbox'"
                        [disabled]="field.readOnly"
                        (change)="updateFormValue($event, i)"
                        (keydown.Enter)="onEnter($event)"
                    ></ion-input>
                    <ion-textarea *ngIf="field.textarea" [disabled]="field.readOnly" auto-grow [formControlName]="i"></ion-textarea>
                    <ion-icon size="large" name="trash" (click)="removeItem(i)"></ion-icon>
                </div>
                <ion-button (click)="addItem()">Add Item</ion-button>
            </div>
        </div>
    `,
    styles: [
        `
            ion-icon {
                align-self: center;
                padding-left: 8px;
            }

            .horizontal {
                padding-bottom: 4px;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextboxComponent extends BaseFormControl {
    @Input()
    field: TextboxField;

    get formArray() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    get formControl() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormControl;
    }

    removeItem(i: number) {
        this.formArray.removeAt(i);
    }

    addItem() {
        this.formArray.push(new UntypedFormControl());
    }

    updateFormValue(e, index?: number) {
        console.log('updateFormValue', this.field);
        if (this.field.type === 'number') {
            const value = e.target.value.trim() === '' ? null : +e.target.value;
            if (this.field.multi) {
                this.formArray.at(index).setValue(value);
            } else {
                this.formControl.setValue(value);
            }
        }
    }

    onEnter(e) {
        e.preventDefault();
    }
}

type TextboxFields = Omit<FormTextField, 'type'> & Omit<FormNumberField, 'type'> & Omit<FormTranslationField, 'type'>;
interface TextboxField extends TextboxFields {
    type: 'textbox' | 'number' | 'translation' | 'html' | 'json';
}
