import { Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { PaginatedItems, snapshot, UserService, ArmyBuilderConfig } from '../../../global';

@Injectable({ providedIn: 'root' })
export class AdminEntityService {
    private entityListUrl = `${this.config.apiBaseUrl}/library/admin`;
    private entities$ = new BehaviorSubject<{ [id: string]: any }>({});
    env = signal(null);

    constructor(
        private userService: UserService,
        private config: ArmyBuilderConfig
    ) {
        this.entities$.subscribe((e) => console.log({ e }));
        this.getEnvironment().subscribe((env) => {
            console.log('!env', env);
            this.env.set(env);
        });
    }

    saveEntity(entity: any, gameId: string, entityType: string, promote = false) {
        console.log('Saving entity...', entity);
        let url = `${this.entityListUrl}/${gameId}/${entityType}`;
        if (promote) {
            url = `${this.config.apiBaseUrl}/admin/promotion/entity/${gameId}/${entityType}/promoteToProd`;
        }
        return this.userService.adminOnlyRoute('post', url, entity).pipe(
            tap((_) => {
                snapshot(this.entities$, (entities) => {
                    entities[entity._id] = entity;
                    this.entities$.next(entities);
                });
            })
        );
    }

    saveMany(entities: any[], gameId: string, entityType: string) {
        console.log('Saving multiple entities...', entities);
        const url = `${this.entityListUrl}/${gameId}/${entityType}`;
        return this.userService.adminOnlyRoute('post', url, entities);
        // .pipe
        // tap((_) => {
        //     snapshot(this.entities$, (entities) => {
        //         entities[entity._id] = entity;
        //         this.entities$.next(entities);
        //     });
        // })
        // ();
    }

    deleteEntity(entity: any, gameId: string, entityType: string) {
        console.log('Deleting entity...', entity);
        const url = `${this.entityListUrl}/${gameId}/${entityType}/${entity._id}`;
        return this.userService.adminOnlyRoute('delete', url);
    }

    getEntities(gameId: string, entityType: string, skip: number, query: string = '', sort: string = '', pageSize = 10) {
        const qs = (key, val) => (val ? `&${key}=${val}` : '');
        const url = `${this.entityListUrl}/${gameId}/${entityType}?pageSize=${pageSize}&skip=${skip}${qs('query', query)}${qs(
            'sort',
            sort || 'name.en|asc'
        )}`;
        return this.userService.adminOnlyRoute('get', url).pipe(map((data) => data as PaginatedItems));
    }

    getEntityByRoute(route: ActivatedRoute) {
        return route.params.pipe(
            map((p) => ({ entityId: p.entityId, entityType: p.entityType, gameId: p.gameId })),
            filter((p) => p?.gameId && p?.entityType && p?.entityId),
            switchMap((x) => this.getEntity(x.gameId, x.entityType, x.entityId))
        );
    }

    getEntity(gameId: string, entityType: string, entityId: string) {
        const url = `${this.entityListUrl}/${gameId}/${entityType}/${entityId}`;
        return this.userService
            .adminOnlyRoute('get', url)
            .pipe(map((data) => data as any))
            .pipe(
                tap((entity) => {
                    snapshot(this.entities$, (entities) => {
                        entities[entity._id] = entity;
                        this.entities$.next(entities);
                    });
                }),
                switchMap((entity) => {
                    return this.entities$.pipe(map((entities) => entities[entity._id]));
                })
            );
    }

    getEnvironment() {
        const url = `${this.config.apiBaseUrl}/admin/promotion/environment`;
        return this.userService.adminOnlyRoute('get', url).pipe(map((data) => data?.environment as any));
    }
}
