import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { UserService, ArmyBuilderConfig } from '../../global';

@Component({
    selector: 'abs-user-admin',
    template: `
        <abs-page-layout title="User Admin">
            <form (ngSubmit)="addUser()" [formGroup]="formGroup" style="border:0;">
                <div class="content">
                    <br />
                    <ion-label position="floating">Username:</ion-label>
                    <ion-input type="text" formControlName="username" (keyup.enter)="addUser()"></ion-input>
                </div>
                <div class="buttons">
                    <ion-button type="submit">Add User</ion-button>
                </div>
            </form>
        </abs-page-layout>
    `
})
export class UserAdminPage {
    formGroup = this.fb.group({
        username: this.fb.control('')
    });
    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private toastController: ToastController,
        private config: ArmyBuilderConfig
    ) {}

    addUser() {
        let { username } = this.formGroup.value;
        const url = `${this.config.apiBaseUrl}/auth/user-admin`;
        console.log('Adding user to app: ' + username);
        this.userService
            .adminOnlyRoute('post', url, { username })
            .toPromise()
            .then((val: any) => {
                this.formGroup.setValue({ username: '' });
                this.presentToast(val.message, 'success');
            })
            .catch((err) => {
                this.presentToast('Error adding user to app: ' + err.error.message, 'danger');
            });
    }

    async presentToast(message, color) {
        const toast = await this.toastController.create({
            message,
            duration: 2000,
            color
        });
        toast.present();
    }
}
