import { Component, EventEmitter, Input, Output, TemplateRef, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { IonItemSliding } from '@ionic/angular';

import { ScrollService } from '../scroll-service/scroll-service';
import { getUniqueId, snapshot } from '../utils';
import { getValueFromTranslationSync } from '../language';

@Component({
    selector: 'abs-list',
    template: `
        <ng-container *ngIf="displayRows$ | async as displayRows">
            <ng-template #rowInner let-item="item" let-itemSliding="itemSliding" let-i="i">
                <ion-item
                    *ngIf="getRouterLink"
                    [routerLink]="getRouterLink(item)"
                    lines="none"
                    [class.active]="(isRowActive && isRowActive(item, i)) || (!!activeStyleField && !!item[activeStyleField])"
                    [class.alt]="item.alt || (item.alt === undefined && i % 2 === 0)"
                    [class.clickable]="itemHover"
                >
                    <ng-template [ngTemplateOutlet]="template ?? defaultTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>

                    <div class="itemOptions" *ngIf="customRowButtonsTemplate || showDelete">
                        <ng-template
                            *ngIf="customRowButtonsTemplate"
                            [ngTemplateOutlet]="customRowButtonsTemplate"
                            [ngTemplateOutletContext]="{ item: item }"
                        ></ng-template>

                        <ion-item-option *ngIf="showDelete" (click)="delete($event, item, itemSliding)">
                            <ion-icon class="deleteIcon" name="trash"></ion-icon>
                        </ion-item-option>
                    </div>
                </ion-item>
                <ion-item
                    *ngIf="!getRouterLink"
                    (click)="itemClicked($event, item, i)"
                    (press)="itemLongPressed(item, i)"
                    lines="none"
                    [class.alt]="item.alt || (item.alt === undefined && i % 2 === 0)"
                    [class.active]="(isRowActive && isRowActive(item, i)) || (!!activeStyleField && !!item[activeStyleField])"
                    [class.clickable]="itemHover"
                >
                    <ng-template [ngTemplateOutlet]="template ?? defaultTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>

                    <div class="itemOptions" *ngIf="customRowButtonsTemplate || showDelete">
                        <ng-template
                            *ngIf="customRowButtonsTemplate"
                            [ngTemplateOutlet]="customRowButtonsTemplate"
                            [ngTemplateOutletContext]="{ item: item }"
                        ></ng-template>
                        <ion-item-option *ngIf="showDelete" (click)="delete($event, item, itemSliding)">
                            <ion-icon class="deleteIcon" name="trash"></ion-icon>
                        </ion-item-option>
                    </div>
                </ion-item>
                <ion-item-options side="end" *ngIf="customRowButtonsTemplate || showDelete">
                    <ng-template
                        *ngIf="customRowButtonsTemplate"
                        [ngTemplateOutlet]="customRowButtonsTemplate"
                        [ngTemplateOutletContext]="{ item: item }"
                    ></ng-template
                    >test1
                    <ion-item-option class="delete deleteIcon" *ngIf="showDelete" (click)="delete($event, item, itemSliding)" expandable
                        ><ion-icon name="trash"></ion-icon
                    ></ion-item-option>
                </ion-item-options>
            </ng-template>

            <ng-template #defaultTemplate let-item="item">
                <div class="itemName">
                    <span>{{ item.name | language | async }}</span>
                </div>
            </ng-template>

            <ng-template #defaultHeaderTemplate let-item="item">
                <ion-label>{{ item.name }}</ion-label>
                <ion-icon
                    *ngIf="collapsibleGroups"
                    [name]="visibleGroups[item.id] ? 'caret-down-outline' : 'caret-up-outline'"
                    (click)="toggleGroup(item.id)"
                ></ion-icon>
            </ng-template>

            <ng-container *ngIf="showSearch">
                <ion-searchbar (ionInput)="updateSearch($event)" [value]="searchVal"></ion-searchbar>
            </ng-container>
            <ion-spinner *ngIf="showLoadingSpinner && !this.items"></ion-spinner>
            <ion-list>
                <div
                    class="row"
                    *ngFor="let item of displayRows; let i = index; trackBy: trackByFn"
                    [class.deleted]="item.deleted"
                    [class.showItemOptions]="customRowButtonsTemplate || showDelete"
                >
                    <ion-item-divider *ngIf="item.isHeader && item.name">
                        <ng-template
                            [ngTemplateOutlet]="headerTemplate ?? defaultHeaderTemplate"
                            [ngTemplateOutletContext]="{ item: item }"
                        ></ng-template>
                    </ion-item-divider>
                    <ion-item-sliding
                        *ngIf="!item.isHeader && (customRowButtonsTemplate || showDelete)"
                        #itemSliding
                        (ionSwipe)="delete($event, item, itemSliding)"
                    >
                        <ng-template
                            [ngTemplateOutlet]="rowInner"
                            [ngTemplateOutletContext]="{ item: item, itemSliding: itemSliding, i: i }"
                        ></ng-template>

                        <ion-item-options>
                            <ng-template
                                *ngIf="customRowButtonsTemplate || showDelete"
                                [ngTemplateOutlet]="customRowButtonsTemplate"
                                [ngTemplateOutletContext]="{ item: item }"
                            ></ng-template>
                            <ion-item-option
                                color="danger"
                                class="deleteIcon"
                                *ngIf="showDelete"
                                (click)="delete($event, item, itemSliding)"
                            >
                                <ion-icon name="trash"></ion-icon>
                            </ion-item-option>
                        </ion-item-options>
                    </ion-item-sliding>

                    <ng-template
                        *ngIf="!item.isHeader && !(customRowButtonsTemplate || showDelete)"
                        [ngTemplateOutlet]="rowInner"
                        [ngTemplateOutletContext]="{ item: item, itemSliding: null, i: i }"
                    ></ng-template>
                </div>
            </ion-list>

            <div *ngIf="noItemsTextKey && items?.length === 0" class="page-placeholder content">
                {{ noItemsTextKey | translate }}
            </div>
            <div *ngIf="noItemsTemplate && items?.length === 0" class="page-placeholder content">
                <ng-template [ngTemplateOutlet]="noItemsTemplate" [ngOutletContext]="context"></ng-template>
            </div>

            <div *ngIf="showSearch && noItemsFromSearchTextKey && displayRows?.length === 0" class="page-placeholder content">
                {{ noItemsFromSearchTextKey | translate }}
            </div>
            <div *ngIf="showSearch && noItemsFromSearchTemplate && displayRows?.length === 0" class="page-placeholder content">
                <ng-template [ngTemplateOutlet]="noItemsFromSearchTemplate" [ngOutletContext]="context"></ng-template>
            </div>
        </ng-container>
    `,
    styles: [
        `
            :host {
                display: flex;
                flex-direction: column;
                position: relative;
                margin-bottom: 20px;
                margin-top: 10px;
            }

            ion-list,
            ion-item {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                --background: none;
                --ion-item-background: none;
                padding: 0;

                /* These were changed to support positioning the period component in the top left on the Bolt Action add unit page.  
                If they need to be removed then be sure to set them in the Bolt Action stylesheet instead so that page doesn't break. */
                contain: unset;
                overflow: visible;
            }

            .clickable {
                cursor: pointer;
            }

            .clickable:hover:before {
                content: '';
                background: rgba(0, 0, 0, 0.1);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                z-index: 2;
            }

            fww-icon {
                margin-right: 10px;
            }

            .row {
                display: flex;
                justify-items: end;
                width: 100%;
            }

            .deleted {
                opacity: 0;
                margin-left: -100%;
                transition: all 0.35s;
            }

            ion-spinner {
                margin: 10px auto;
            }

            .itemOptions {
                display: none;
            }

            @media (min-width: 601px) {
                .showItemOptions ion-item {
                    --inner-padding-end: 0 !important;
                }
                .showItemOptions .itemOptions {
                    display: flex;
                    padding: 0 8px;
                }
                .showItemOptions .itemOptions ion-icon {
                    margin: 0 8px;
                }
            }
        `
    ]
})
export class ListComponent implements OnChanges, OnDestroy {
    @Input() debugId: string;
    @Input() showLoadingSpinner = true;
    @Input() template: TemplateRef<any> = null;
    @Input() customRowButtonsTemplate: TemplateRef<any> = null;
    @Input() headerTemplate: TemplateRef<any> = null;
    @Input() items: any[] = [];
    @Input() noItemsTextKey: string = null;
    @Input() noItemsTemplate: TemplateRef<any> = null;
    @Input() noItemsFromSearchTextKey: string = null;
    @Input() noItemsFromSearchTemplate: TemplateRef<any> = null;
    @Input() activeStyleField: string = null;
    @Input() showSearch: boolean = false;
    @Input() showDelete: boolean = false;
    @Input() includeInSearchOverrideField: string = 'selected';
    @Input() searchVal = '';
    @Output()
    searchValueUpdated = new EventEmitter<string>();

    @Input() itemHover = true;
    @Input() isRowActive: (item: any, index: number) => boolean;
    @Input() getRouterLink: (item: any) => string[];
    @Output() itemSelect = new EventEmitter<{ e: any; item: any; i: number }>();
    @Output() itemLongPress = new EventEmitter<{ item: any; i: number }>();
    @Output() deleteItem = new EventEmitter<{
        item: any;
        i: number;
        slidingItem: IonItemSliding;
        callback: (deleted: boolean) => void;
    }>();

    firstRender = true;

    @ViewChild('defaultTemplate') defaultTemplate;
    @ViewChild('defaultHeaderTemplate') defaultHeaderTemplate;

    @Input() groupBy: string = null;
    @Input() groupNames: string[];
    @Input() groupNameField: string = null;
    @Input() showEmptyGroups: boolean = false;
    @Input() collapsibleGroups: boolean = false;
    currentHeader: string;
    visibleGroups = {};

    @Input() trackBy: (i: number, item: any) => string;

    displayRows$ = new ReplaySubject<any[]>();
    itemListIds: number[]; // Used to find the index of an item being deleted
    offsetIndex: number[];

    destroy$ = new ReplaySubject();

    constructor(
        private router: Router,
        private scrollService: ScrollService,
        private route: ActivatedRoute
    ) {}

    trackByFn(_i, item) {
        if (this.trackBy) {
            return this.trackBy(_i, item);
        }
        return item.name + ':' + item.count;
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'items': {
                        if (changes.items.currentValue && changes.items.currentValue !== changes.items.previousValue) {
                            this.updateItems();
                        }
                    }
                }
            }
        }
    }

    filterBySearchValue = (item: any, searchVal: string) => {
        const translatedName = item.name?.en ? getValueFromTranslationSync(item.name) : item.name;
        return !searchVal || item[this.includeInSearchOverrideField] || translatedName.toUpperCase().includes(searchVal.toUpperCase());
    };

    updateSearch(e: any) {
        const value = e.detail.value;
        this.searchVal = value;
        this.updateItems();
        this.searchValueUpdated.emit(this.searchVal);
    }

    async updateItems() {
        if (!this.items) {
            return;
        }
        this.currentHeader = '';
        const allItemsAndHeaders = [
            ...this.items
                .filter((x) => !!x && !x.hide)
                .filter((x) => this.filterBySearchValue(x, this.searchVal))
                .sort((a, b) => {
                    if (!this.groupBy) {
                        return 0;
                    }
                    if (this.groupNames?.length > 0) {
                        return this.groupNames.indexOf(a[this.groupBy]) - this.groupNames.indexOf(b[this.groupBy]);
                    }
                    return a[this.groupBy]?.toString().localeCompare(b[this.groupBy]?.toString());
                })
                .map((e, i) => ({
                    ...e,
                    alt: i % 2 === 0,
                    uniqueListId: getUniqueId()
                }))
        ];

        this.itemListIds = allItemsAndHeaders.map((x) => x.uniqueListId);

        if (this.groupBy) {
            let groups = this.groupNames?.map((x) => ({ id: x, name: x }));

            const groupNameField = this.groupNameField || this.groupBy;
            const itemGroups = allItemsAndHeaders.map((x) => ({ id: x[this.groupBy], name: x[groupNameField] }));
            if (!groups || groups.length === 0) {
                groups = itemGroups.filter((g, i) => itemGroups.findIndex((x) => x.id === g.id) === i);
            }

            // Insert groupname rows
            let currentItemIndex = 0;
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                let headersAdded = 0;
                if (group && group.name) {
                    allItemsAndHeaders.splice(currentItemIndex, 0, { isHeader: true, ...group } as any);
                    headersAdded = 1;
                }

                if (this.visibleGroups[group.id] === undefined) {
                    this.visibleGroups[group.id] = true;
                }

                const itemCount = allItemsAndHeaders.filter((x) => x[this.groupBy] === group.id).length;
                currentItemIndex += itemCount + headersAdded;
            }
        }

        this.displayRows$.next(
            allItemsAndHeaders.filter((x) => !x[this.groupBy || 'groupKey'] || this.visibleGroups[x[this.groupBy || 'groupKey']])
        );
    }

    itemClicked(e: any, item: any, i: number) {
        this.itemSelect.emit({ e, item: item, i });
    }

    itemLongPressed(item: any, i: number) {
        this.itemLongPress.emit({ item: item, i });
    }

    isNaN(value: any) {
        return isNaN(value);
    }

    async delete(event: any, item: any, slidingItem: IonItemSliding) {
        event.stopPropagation();
        event.preventDefault();
        await slidingItem.close();

        const itemOnlyIndex = this.itemListIds.indexOf(item.uniqueListId);

        const setItemDeleted = (deleted: boolean) => {
            item.deleted = deleted;
            snapshot(this.displayRows$, (displayRows) => {
                const allRowsIndex = displayRows.indexOf(item);
                if (displayRows[allRowsIndex]) {
                    displayRows[allRowsIndex].deleted = deleted;
                }
            });
        };
        setItemDeleted(true);
        setTimeout(() => {
            this.deleteItem.emit({ item: item, i: itemOnlyIndex, slidingItem, callback: setItemDeleted });
        }, 350);
    }

    ngOnDestroy() {
        console.log(this.debugId, 'ngOnDestroy');
        this.destroy$.next(true);
    }

    toggleGroup(groupKey) {
        this.visibleGroups[groupKey] = !this.visibleGroups[groupKey];
        this.updateItems();
    }
}

// export interface NamedItem {
//     name: Translation;
//     isHeader?: number;
//     yPos: number;
//     height: number;
//     alt?: boolean;
//     deleted?: boolean; // used to animate a deleted item disappearing
//     hide?: boolean;
// }
