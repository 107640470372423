import { Component, Input } from '@angular/core';

import { BaseFormControl } from '../base-control';
import { FormArrayField } from '../form-field';
import { BaseFormArrayComponent } from '../form-array/form-array.component';
import { BaseComplexTagsComponent } from '../complex-tags/complex-tags.component';
import { BaseFormSectionComponent } from '../section/form-section.component';
import { BaseFormGroupComponent } from '../form-group/form-group.component';

@Component({
    selector: 'abs-universal-field',
    template: `
        <ng-container *ngIf="field.hidden">
            <button class="showMore" (click)="toggleHiddenField()">{{ visible ? 'Show Less' : 'Show More' }}</button>
        </ng-container>
        <div [class.hidden]="field.hidden && !visible">
            <abs-textbox *ngIf="field.type === 'textbox' || field.type === 'number'" [field]="field" [formValue]="formValue"></abs-textbox>
            <abs-date-picker *ngIf="field.type === 'datepicker'" [field]="field" [formValue]="formValue"></abs-date-picker>
            <abs-code *ngIf="['html', 'json'].includes(field.type)" [field]="field" [formValue]="formValue"></abs-code>
            <abs-translation *ngIf="field.type === 'translation'" [field]="field" [formValue]="formValue"></abs-translation>
            <abs-select *ngIf="field.type === 'select'" [field]="field" [formValue]="formValue"></abs-select>
            <abs-tags *ngIf="field.type === 'tags'" [field]="field" [formValue]="formValue"></abs-tags>
            <abs-tag *ngIf="field.type === 'tag'" [field]="field" [formValue]="formValue"></abs-tag>
            <abs-translatable-tags
                *ngIf="field.type === 'translatableTags'"
                [field]="field"
                [formValue]="formValue"
            ></abs-translatable-tags>
            <abs-complex-tags *ngIf="field.type === 'complexTags'" [field]="field" [formValue]="formValue"></abs-complex-tags>
            <abs-form-array *ngIf="field.type === 'array'" [field]="field" [formValue]="formValue"></abs-form-array>
            <abs-form-group *ngIf="field.type === 'group'" [field]="field" [formValue]="formValue"></abs-form-group>
            <abs-form-section *ngIf="field.type === 'section'" [field]="field" [formValue]="formValue"></abs-form-section>
            <abs-checkbox *ngIf="field.type === 'checkbox'" [field]="field" [formValue]="formValue"></abs-checkbox>
            <abs-item-select-multi *ngIf="field.type === 'itemPickerMulti'" [field]="field" [formValue]="formValue">
            </abs-item-select-multi>
        </div>
    `,
    styles: [
        `
            h2,
            p {
                padding-left: 20px;
            }

            .hidden {
                display: none;
            }

            .showMore {
                margin: 0;
                background: none;
                text-decoration: underline;
                color: blue;
            }
        `
    ]
})
export class UniversalFieldComponent extends BaseFormControl {
    @Input()
    field: any;

    visible = false;
    toggleHiddenField() {
        this.visible = !this.visible;
        return false;
    }
}

@Component({
    selector: 'abs-form-array',
    templateUrl: '../form-array/form-array.component.html',
    styleUrls: ['../form-array/form-array.component.scss']
})
export class FormArrayComponent extends BaseFormArrayComponent {
    @Input()
    field: FormArrayField;

    @Input()
    header: string;
}

@Component({
    selector: 'abs-complex-tags',
    templateUrl: '../complex-tags/complex-tags.component.html',
    styleUrls: ['../complex-tags/complex-tags.component.scss']
})
export class ComplexTagsComponent extends BaseComplexTagsComponent {}

@Component({
    selector: 'abs-form-section',
    templateUrl: '../section/form-section.component.html'
})
export class FormSectionComponent extends BaseFormSectionComponent {}

@Component({
    selector: 'abs-form-group',
    templateUrl: '../form-group/form-group.component.html'
})
export class FormGroupComponent extends BaseFormGroupComponent {}
