import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { debounceTime, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { AlertService, formDiff, snapshot } from '../../../../global';
import { EntityFormConfig } from '../../service/entity-form-config';
import { AdminEntityService } from '../../service/entity.service';
import { FormConfig } from '../../../config';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'abs-entity-details',
    template: `
        <abs-page-layout
            *ngIf="entity$ | async as entity"
            [title]="entity.name?.en || entity.name || entity.rawValue?.name?.en || entity.rawValue?.name"
        >
            <abs-form
                *ngLet="{ entityTypeMetaData: entityTypeMetaData$ | async } as props"
                [config]="this.formConfig$ | async"
                [value]="entity$ | async"
                (formSubmit)="onSubmit($event)"
            >
                <div class="floatingButtons">
                    <ion-button size="default" color="danger" (click)="deleteEntity(entity)"
                        >Delete {{ props.entityTypeMetaData.entityName }}</ion-button
                    >
                    <ion-button size="default" color="warning" (click)="duplicateEntity(entity)"
                        >Duplicate {{ props.entityTypeMetaData.entityName }}</ion-button
                    >
                    <div>
                        <ion-button size="default" type="submit">Save</ion-button>
                        <ion-button size="default" (click)="promote()" *ngIf="env() === 'staging'"> Promote </ion-button>
                        <button style="display:none" #promoteBtn type="submit" name="promoteBtn">Promote</button>
                    </div>
                </div>
            </abs-form>
        </abs-page-layout>
    `,
    styles: [
        `
            abs-form {
                margin-bottom: 50px;
                padding-bottom: 100px;
                display: block;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityDetailsComponent {
    formConfig$ = this.route.params.pipe(
        switchMap((p) => this.entityFormConfig.forms[p.gameId][p.entityType]),
        map((config) => {
            if (config.formDefinition) {
                return config;
            }

            console.log('Form config change');

            return {
                ...config,
                raw: true,
                formDefinition: [
                    {
                        type: 'json',
                        name: 'rawValue'
                    }
                ]
            } as FormConfig;
        })
    );

    env = this.entityService.env;

    entityTypeMetaData$ = this.route.params.pipe(map((p) => this.entityFormConfig.entityTypeMetaData[p.entityType]));

    entity$ = combineLatest([this.entityService.getEntityByRoute(this.route), this.formConfig$]).pipe(
        debounceTime(1000),
        map(([entity, fc]) => {
            if (fc.raw) {
                return {
                    rawValue: entity
                };
            }

            return entity;
        }),
        debounceTime(100),
        tap((x) => console.log('Form entity change')),
        shareReplay(1)
    );
    entity = toSignal(this.entity$);

    gameId = this.route.snapshot.params.gameId;
    entityType = this.route.snapshot.params.entityType;

    alert = inject(AlertService);

    @ViewChild('promoteBtn')
    promoteBtn: any;

    constructor(
        public entityService: AdminEntityService,
        private toastController: ToastController,
        private route: ActivatedRoute,
        private alertController: AlertController,
        private router: Router,
        private entityFormConfig: EntityFormConfig
    ) {}

    promote() {
        this.promoteBtn.nativeElement.click();
    }

    async onSubmit(e) {
        const value = e.formValue;
        const promote = e.originalEvent?.submitter?.name === 'promoteBtn';

        if (promote) {
            const res = await this.alert.showConfirm('Are you sure?', 'Are you sure you want to promote this entity?', {
                ok: 'Yes',
                cancel: 'No'
            });
            if (res.role === 'cancel') {
                return;
            }
        }

        snapshot(combineLatest([this.formConfig$, this.entity$]), ([fc, entity]) => {
            let output = value;
            let input = entity;

            if (fc.raw) {
                output = typeof value.rawValue === 'string' ? JSON.parse(value.rawValue) : value.rawValue;
                input = entity.rawValue;
            }
            console.log('Entity from form:', output);

            if (fc.dryRun) {
                const diffResult = formDiff(output, input);
                console.log('-----------------');
                console.log('Form input:', input);
                console.log('Form output:', output);
                console.log('Form data clean?', diffResult.equal);
                if (!diffResult.equal) {
                    console.log('Changed fields:', diffResult.changedFields);
                }
                console.log('Save skipped');
                console.log('-----------------');
            } else {
                this.entityService
                    .saveEntity(output, this.gameId, this.entityType, promote)
                    .toPromise()
                    .then((x) => {
                        console.log({ x });
                        this.presentToast('Save successful!', 'success');
                    })
                    .catch((err) => this.handleHttpError(err));
            }
        });
    }

    handleHttpError(err) {
        // error
        if (err.status === 403) {
            this.presentToast("You aren't authorized to do that.", 'danger');
        } else {
            this.presentToast('Oops something went wrong.', 'danger');
        }
        console.error(err);
    }

    async presentToast(message, color) {
        const toast = await this.toastController.create({
            message,
            duration: 2000,
            color
        });
        toast.present();
    }

    async deleteEntity(entity: any) {
        snapshot(this.entityTypeMetaData$, async (entityTypeMetaData) => {
            const alert = await this.alertController.create({
                header: 'Delete ' + entityTypeMetaData.entityName,
                message: 'Are you sure?  This cannot be undone.',
                buttons: [
                    {
                        text: 'No',
                        handler: () => {}
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            snapshot(combineLatest([this.formConfig$, this.entity$]), ([fc, entityData]) => {
                                let entity = entityData;
                                if (fc.raw) {
                                    entity = entity.rawValue;
                                }
                                this.entityService
                                    .deleteEntity(entity, entity.gameId, this.route.snapshot.params.entityType)
                                    .toPromise()
                                    .then((x) => {
                                        this.router.navigate(['..'], { relativeTo: this.route });
                                    })
                                    .catch((err) => this.handleHttpError(err));
                            });
                        }
                    }
                ]
            });
            alert.present();
        });
    }

    duplicateEntity(entity: any) {
        snapshot(this.entityTypeMetaData$, (entityTypeMetaData) => {
            let entityData = entity;
            if (entity.rawValue) {
                entityData = entity.rawValue;
            }

            let name;
            if (typeof entityData.name === 'string') {
                name = entityData.name + ' Copy';
            } else if (typeof entityData.name === 'object') {
                name = {
                    ...entityData.name,
                    en: entityData.name.en + ' Copy'
                };
            }

            const entityCopy = {
                ...entityData,
                name
            };
            delete entityCopy._id;
            delete entityCopy.shortId;
            this.entityService
                .saveEntity(entityCopy, entityData.gameId, this.entityType)
                .toPromise()
                .then((res) => {
                    this.router.navigate(['..', res[0]], { relativeTo: this.route });
                    this.presentToast(entityTypeMetaData.entityName + ' Duplicated Successfully', 'success');
                })
                .catch((err) => this.handleHttpError(err));
        });
    }
}
