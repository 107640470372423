import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslationComponent } from './translation/translation.component';
import { TagLabelPipe, TagsComponent } from './tags/tags.component';
import { IonicModule } from '@ionic/angular';
import { TextboxComponent } from './textbox/textbox.component';
import { SelectComponent } from './select/select.component';
import {
    ComplexTagsComponent,
    FormArrayComponent,
    FormGroupComponent,
    FormSectionComponent,
    UniversalFieldComponent
} from './universal-field/universal-field.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TranslatableTagsComponent } from './translatable-tags/translatable-tags.component';
import { NgLetModule } from 'ng-let';
import { AutoCompleteModule } from './autocomplete/autocomplete.module';
import { ListModule, ModalLayoutModule } from '../../global';
import { TranslationModalComponent } from './translatable-tags/translation-modal.component';
import { SelectOptionsPipe } from './select/select-options.pipe';
import { ArrayFieldItemNamePipe } from './form-array/array-field-item-name.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FormComponent } from './form/form.component';
import { CodeFormComponent } from './code/code.component';
import { TagComponent } from './tag/tag.component';
import { FormLabelPipe } from './form-label.pipe';
import { ItemSelectMultiComponent } from './item-select-multi/item-select-multi.component';
import { DatePickerComponent } from './date-picker/date-picker.component';

const FORM_CONTROL_COMPONENTS = [
    FormComponent,
    TranslationComponent,
    TagComponent,
    TagsComponent,
    ComplexTagsComponent,
    TranslatableTagsComponent,
    TextboxComponent,
    SelectComponent,
    FormArrayComponent,
    FormGroupComponent,
    UniversalFieldComponent,
    CheckboxComponent,
    FormSectionComponent,
    TranslationModalComponent,
    SelectOptionsPipe,
    ArrayFieldItemNamePipe,
    TooltipComponent,
    CodeFormComponent,
    TagLabelPipe,
    FormLabelPipe,
    ItemSelectMultiComponent,
    DatePickerComponent
];

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, IonicModule, NgLetModule, AutoCompleteModule, ModalLayoutModule, ListModule],
    exports: [...FORM_CONTROL_COMPONENTS],
    declarations: [...FORM_CONTROL_COMPONENTS],
    providers: []
})
export class FormControlsModule {}
