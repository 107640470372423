import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import { getValueFromTranslationSync, LANGUAGE } from '../../global/language/selectLanguage';
import { Unit, UnitTemplate } from '../models';

@Pipe({
    name: 'unitName'
})
export class UnitNamePipe implements PipeTransform {
    transform(unit: Unit | UnitTemplate, displayClassWithCustomName = false): any {
        return getUnitNameSync(unit, displayClassWithCustomName);
    }
}

export const getUnitName = (unit: Unit | UnitTemplate, displayClassWithCustomName = false): Observable<string> => {
    return of(getUnitNameSync(unit, displayClassWithCustomName));
};

export const getUnitNameSync = (unit: Unit | UnitTemplate, displayClassWithCustomName = false): string => {
    const unitLike: any = unit;
    if (!unitLike) {
        return null;
    }
    if (unitLike.unitTemplate) {
        const unitTemplate = unitLike.unitTemplate as UnitTemplate;

        const variant = unitTemplate.variants?.find((v) => v.id === unitLike.variantId);
        const variantName = variant?.name?.en ? variant.name : null;
        const opts = unitLike.selectedOptions.map((soid) => unitTemplate.options.find((o) => parseInt(o.id as any) === parseInt(soid)));
        const option = opts.find(
            (x: any) =>
                x?.unitNameOverride === true || x?.nameOverride === true || x?.unitNameOverride?.[LANGUAGE] || x?.nameOverride?.[LANGUAGE]
        );
        let name = variantName || unitTemplate.name;
        const override = option?.unitNameOverride || option?.nameOverride;

        if (override && typeof override === 'object' && !!override[LANGUAGE]) {
            name = override;
        } else if (override === true) {
            name = option.name;
        }

        let originalName = getValueFromTranslationSync(name);

        const suffixOpt = opts.find((x: any) => x?.unitNameSuffix?.[LANGUAGE]);
        if (suffixOpt) {
            const suffix = suffixOpt.unitNameSuffix ? getValueFromTranslationSync(suffixOpt.unitNameSuffix) || '' : '';

            originalName = `${originalName}${suffix}`;
        }

        let customName = unitLike.customName;
        if (customName && displayClassWithCustomName) {
            customName = `${customName} (${originalName})`;
        }

        return customName ? customName : originalName;
    }

    return getValueFromTranslationSync(unitLike.name);
};
