import { Component, Input } from '@angular/core';

import { BaseFormControl } from '../base-control';
import { FormSelectField } from '../form-field';

@Component({
    selector: 'abs-select',
    template: `
        <div class="form-group" *ngIf="parentFormGroup && debouncedFormVal$" [formGroup]="parentFormGroup">
            <label *ngIf="!field.hideLabel"
                >{{ field.label || (field.name | formLabel) }}
                <ion-icon name="information-circle" *ngIf="field.description" (click)="showDescription($event)"></ion-icon
            ></label>

            @if (debouncedFormVal$ | async; as debouncedFormVal) {
                <ion-select
                    interface="popover"
                    [interfaceOptions]="{ cssClass: 'popover-wide' }"
                    [formControlName]="field.name"
                    [multiple]="field.multi"
                    [label]="field.label || (field.name | titlecase)"
                >
                    <ion-select-option
                        *ngFor="let opt of this.field | absSelectOptions: debouncedFormVal : parentFormGroup | async"
                        [value]="opt.value"
                        >{{ opt.label }}</ion-select-option
                    >
                </ion-select>
            }
        </div>
    `
})
export class SelectComponent extends BaseFormControl {
    @Input()
    field: FormSelectField;
}
