import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NativeStorageService, resetAppState } from '../data';
import { markLoginChecked, SettingsService } from '../settings';
import { ArmyBuilderConfig } from '../config';
import { snapshot } from '../utils';
import { DataSyncService } from '../sync';
import { HttpClientWithInFlightCache } from '../httpClient';
import { LoginSelector } from '../settings/service/selectors';
import { AlertService } from '../alert/alert';

@Injectable({ providedIn: 'root' })
export class UserService {
    login$ = this.settingsService.login$;
    loggedIn$ = this.settingsService.loggedIn$;
    loginChecked$ = this.settingsService.loginChecked$;
    refreshInProgress$ = new BehaviorSubject<boolean>(false);
    isAdmin$ = this.settingsService.isAdmin$;
    private userIsLoggedIn = false; // Used to check if the user was just logged out
    constructor(
        protected httpClient: HttpClientWithInFlightCache,
        protected settingsService: SettingsService,
        protected store: Store,
        private syncService: DataSyncService,
        private storage: NativeStorageService,
        protected config: ArmyBuilderConfig,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService
    ) {
        setTimeout(() => {
            this.store
                .pipe(
                    select(LoginSelector),
                    filter((l) => l?.user?.accessToken),
                    map((loginData) => loginData.user?.id),
                    distinctUntilChanged()
                ) // Doesn't use settingsService.login$ because that won't resolve until checked = true
                .subscribe(async (user) => {
                    if (!user) {
                        return;
                    }

                    console.log('Checking active login following initial load');
                    await this.checkActiveLogin(true);
                    this.store.dispatch(markLoginChecked({ checked: true }));
                });

            // Check every 5 minutes to make sure user login has not expired
            timer(0, this.config.loginPollTime)
                .pipe(
                    withLatestFrom(this.settingsService.login$),
                    map(([_, l]) => l),
                    filter((l) => {
                        console.log({ l });
                        return l?.user?.accessToken;
                    }),
                    filter(() => document.hasFocus()),
                    map((loginData) => loginData.user)
                )
                .subscribe(async (user) => {
                    if (!user) {
                        return;
                    }

                    console.log('**** Checking active login on timer');
                    try {
                        const isLoginActive = await this.checkActiveLogin(false);
                        if (!isLoginActive) {
                            this.router.navigate(['/']);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                });

            this.settingsService.loggedIn$.subscribe((loggedIn) => {
                if (this.userIsLoggedIn && !loggedIn) {
                    console.log("Navigating back to home because user isn't logged in");
                    this.router.navigateByUrl('/');
                }
                this.userIsLoggedIn = loggedIn;
            });
        }, 1000);
    }

    async checkActiveLogin(syncAfterLogin = false): Promise<boolean> {
        const checkTokenRes = await this.checkTokenRes();
        console.log({ checkTokenRes });
        // Check if the login token has expired
        if (checkTokenRes === 401) {
            this.logout('LOGIN_EXPIRED');
        } else if (checkTokenRes === 409) {
            this.logout('LOGIN_EXPIRED');
        } else if (checkTokenRes === 403) {
            this.refreshAccessToken(this.config.apiBaseUrl + '/auth/refresh', null);
        } else if (syncAfterLogin) {
            // Sync on first login
            console.log('checkActiveLogin: sync data');
        }
        this.syncService.sync();

        return ![401, 409, 500].includes(checkTokenRes);
    }

    adminOnlyRoute(verb: 'get' | 'post' | 'delete' | 'put', url: string, payload?: any) {
        return this.login$.pipe(
            take(1),
            filter((l) => l.user?.roles?.includes(this.config.roles.admin)),
            switchMap((l) => {
                switch (verb) {
                    case 'post':
                        return this.httpClient.post(url, payload);
                    case 'put':
                        return this.httpClient.put(url, payload);
                    case 'get':
                        return this.httpClient.get(url);
                    case 'delete':
                        return this.httpClient.delete(url);
                }
            })
        );
    }

    async login(payload: { token: string }) {
        const url = this.config.apiBaseUrl + '/auth/login';
        await this.refreshAccessToken(url, payload);
    }

    async loginWithCredentials(payload: { email: string; password: string }) {
        const url = this.config.apiBaseUrl + '/auth/login';
        await this.refreshAccessToken(url, payload);
    }

    logout(error: string = null) {
        console.log('logout', error);
        this.clearUserData();
        snapshot(this.settingsService.login$, (loginState) => {
            this.settingsService.updateValue('global', 'login', { ...loginState, user: undefined, error });
            this.httpClient.post(this.config.apiBaseUrl + '/auth/log-out', {}).subscribe((res) => {
                console.log('Log out result:', res);

                // Temporarily disabled due to an intermittent refresh loop when logging out.
                // The setTimeout might fix this but I can't be sure right now. - Dec 9
                // setTimeout(() => window.location.href = '/', 1)
            });
        });
    }

    clearUserData() {
        this.storage.clear();
        this.store.dispatch(resetAppState());
    }

    private async checkTokenRes(): Promise<number> {
        const device = await firstValueFrom(this.config.device$);

        console.log('**** checkTokenRes (checking if refresh is in progress)');
        const l = await firstValueFrom(
            combineLatest([this.login$, this.refreshInProgress$]).pipe(
                filter(([_l, refreshInProgress]) => !refreshInProgress),
                map(([l]) => l)
            )
        );
        console.log('**** checkTokenRes (refresh not in progress)', l);
        const authHeaders = {
            Authorization: l.user ? 'Bearer ' + l.user.accessToken : null,
            device: JSON.stringify(device)
        };

        console.log({ checkTokenResAccessToken: l.user.accessToken });
        return await firstValueFrom(
            this.httpClient
                .get(this.config.apiBaseUrl + '/auth/checkToken', {
                    headers: { ...this.config.globalRequestHeaders, ...authHeaders },
                    withCredentials: true
                    // observe: 'response'
                })
                .pipe(
                    catchError((err) => {
                        if (err.status) {
                            return of({ status: err.status });
                        }
                        return of({ status: 500 });
                    }),
                    map((res) => res.status as number)
                )
        );
    }

    getStats(unit: string, threshold: number) {
        const url = `${this.config.apiBaseUrl}/user/stats/${unit}/${threshold}`;
        return firstValueFrom(this.httpClient.get(url));
    }

    archiveStats() {
        const url = this.config.apiBaseUrl + '/user/stats/archive';
        return firstValueFrom(this.httpClient.post(url, null));
    }

    deleteAccount() {
        snapshot(this.settingsService.login$, (loginState) => {
            this.httpClient
                .delete(this.config.apiBaseUrl + '/userData', null, {})
                .pipe(
                    catchError((err) => {
                        console.error('Could not delete user account: ', err);
                        return of(null);
                    })
                )
                .subscribe((res) => {
                    console.log({ res });
                    if (res) {
                        this.logout();
                        window.location.href = '/';
                    } else {
                        this.alertService.showAlert(
                            this.translateService.instant('GLOBAL.SETTINGS.ACCOUNT.DELETE.ERROR.TITLE'),
                            this.translateService.instant('GLOBAL.SETTINGS.ACCOUNT.DELETE.ERROR.DESCRIPTION')
                        );
                    }
                });
        });
    }

    // checkSubscriptionStatus() {
    //     try {
    //         const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
    //         // access latest customerInfo
    //     } catch (e) {
    //         // Handle errors fetching customer info
    //     }
    // }

    async refreshAccessToken(url: string, payload: any) {
        console.log('**** refreshAccessToken start');
        this.refreshInProgress$.next(true);
        const loginState = await firstValueFrom(this.settingsService.login$);

        this.settingsService.updateValue('global', 'login', { ...loginState, loading: true });
        const accessTokenBefore = loginState.user?.accessToken;
        console.log('**** refreshAccessToken before', loginState);
        const data = await firstValueFrom(
            this.httpClient
                .post(url, payload, {
                    headers: { ...this.config.globalRequestHeaders },
                    withCredentials: true,
                    requiresLogin: false
                })
                .pipe(
                    take(1),
                    catchError((err) => of({ error: err.status }))
                )
        );

        console.log('**** refreshAccessToken after', data);

        const newLoginState = {
            ...loginState,
            loading: false,
            checked: true,
            error: data.error,
            user: data.error ? undefined : data
        };
        const accessTokenAfter = data.accessToken;

        console.log({ accessTokenBefore, accessTokenAfter, equal: accessTokenBefore === accessTokenAfter });

        console.log('newLoginState', newLoginState);
        this.settingsService.updateValue('global', 'login', newLoginState);
        this.syncService.sync();
        this.refreshInProgress$.next(false);
        console.log('**** refreshAccessToken end');
        return newLoginState;
    }

    usersUrl = this.config.apiBaseUrl + '/user/lookup/';
    async lookupUpser(email: string) {
        return firstValueFrom(
            this.httpClient.get(this.usersUrl + email, {
                headers: { ...this.config.globalRequestHeaders },
                withCredentials: true,
                requiresLogin: true
            })
        );
    }
}
