import { ErrorHandler, Injectable } from '@angular/core';
import { DebugService, Logger } from './debug.service';
import { AppInjector } from '../app.injector';

@Injectable()
export class ErrorLogger extends ErrorHandler {
    debug: Logger;

    handleError(error) {
        if (!this.debug) {
            try {
                this.debug = AppInjector.get(DebugService).getInstance('[Error]');
            } catch (injectErr) {
                // console.error(injectErr);
            }
        }
        if (this.debug) {
            try {
                this.debug.log(`${error.message}`);
            } catch (injectErr) {
                // console.error(injectErr);
            }
        }
        console.log({ error });
        super.handleError(error);
        throw error;
    }
}
