import { Component, inject, Input, OnChanges, TemplateRef, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { Modal } from '../../../global/modal';
import { SettingsService } from '../../../global/settings';
import { Force } from '../../models';

import { DEFAULT_PDF_CONFIG, DEFAULT_PDF_FIELDS, PDFConfig, PDFSettingsFields } from './pdf-config';
import { PDFService } from './pdf-service';
import { GAME_ID } from '../../../global/game-id';
import { getValueFromTranslationSync } from 'army-builder-shared';

@Component({
    selector: 'abs-pdf-settings',
    template: `
        <ng-template #pdfSettings>
            <ion-list>
                <ion-item *ngIf="_fields.specialRules">
                    <ion-label>{{ 'GLOBAL.PDF.INCLUDE_SPECIAL_RULES' | translate }}</ion-label>
                    <ion-select [(ngModel)]="pdfConfig.specialRules">
                        <ion-select-option value="FULL">{{ 'GLOBAL.PDF.FULL' | translate }}</ion-select-option>
                        <ion-select-option value="SUMMARY">{{ 'GLOBAL.PDF.SUMMARY' | translate }}</ion-select-option>
                        <ion-select-option value="NONE">{{ 'GLOBAL.PDF.NONE' | translate }}</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item *ngIf="_fields.weaponData">
                    <ion-label>{{ 'GLOBAL.PDF.INCLUDE_WEAPON_DATA' | translate }}</ion-label>
                    <ion-checkbox [(ngModel)]="pdfConfig.includeWeaponData"></ion-checkbox>
                </ion-item>
                <ion-item *ngIf="_fields.selectedOptions">
                    <ion-label>{{ 'GLOBAL.PDF.INCLUDE_SELECTED_OPTIONS' | translate }}</ion-label>
                    <ion-checkbox [(ngModel)]="pdfConfig.selectedOptions"></ion-checkbox>
                </ion-item>
                <ion-item *ngIf="_fields.unitData">
                    <ion-label>{{ 'GLOBAL.PDF.INCLUDE_UNIT_DATA' | translate }}</ion-label>
                    <ion-checkbox [(ngModel)]="pdfConfig.includeUnitData"></ion-checkbox>
                </ion-item>
                <ion-item *ngIf="_fields.includePageNumbers">
                    <ion-label>{{ 'GLOBAL.PDF.INCLUDE_PAGE_NUMBERS' | translate }}</ion-label>
                    <ion-checkbox [(ngModel)]="pdfConfig.includePageNumbers"></ion-checkbox>
                </ion-item>
                <ion-item *ngIf="_fields.newPagePerPlatoon">
                    <ion-label>{{ 'GLOBAL.PDF.NEW_PAGE_PER_PLATOON' | translate }}</ion-label>
                    <ion-checkbox [(ngModel)]="pdfConfig.newPagePerPlatoon"></ion-checkbox>
                </ion-item>
                <ion-item *ngIf="_fields.baseFontSize">
                    <ion-label>{{ 'GLOBAL.PDF.BASE_FONT_SIZE' | translate }}</ion-label>
                    <ion-range
                        aria-label="Font Size"
                        [ticks]="true"
                        [step]="2"
                        [snaps]="true"
                        [min]="6"
                        [max]="14"
                        [(ngModel)]="pdfConfig.baseFontSize"
                        [pin]="true"
                    >
                    </ion-range>
                </ion-item>
            </ion-list>
        </ng-template>

        <ng-template #pdfButtons>
            <ion-button>{{ 'GLOBAL.BUTTONS.CANCEL' | translate }}</ion-button>
            <ion-button (click)="this.exportPDF()">{{ 'GLOBAL.BUTTONS.EXPORT' | translate }}</ion-button>
        </ng-template>
    `,
    styles: [
        `
            ion-range {
                padding-top: 0;
            }
        `
    ]
})
export class PDFSettingsComponent implements OnChanges {
    pdfConfig: PDFConfig;
    pdfConfig$ = this.settingsService.settings$.pipe(
        map((s) => ({
            ...DEFAULT_PDF_CONFIG,
            ...(s[this.gameId]?.pdfConfig || {})
        }))
    );

    @ViewChild('pdfSettings')
    pdfSettings: TemplateRef<any>;

    @ViewChild('pdfButtons')
    pdfButtons: TemplateRef<any>;

    @Input()
    force: Force;

    @Input()
    fields: PDFSettingsFields;

    _fields: PDFSettingsFields;

    private gameId: string = inject(GAME_ID);

    constructor(
        private modal: Modal,
        private popoverController: PopoverController,
        private settingsService: SettingsService,
        private pdfService: PDFService,
        private translateService: TranslateService
    ) {
        this.pdfConfig$.subscribe((x) => (this.pdfConfig = { ...x }));
    }

    ngOnChanges() {
        this._fields = {
            ...DEFAULT_PDF_FIELDS,
            ...(this.fields || {})
        };
    }

    public show(props: any = {}) {
        this.modal.show({
            props: {
                title: this.translateService.instant('GLOBAL.PDF.SETTINGS'),
                contentTemplate: this.pdfSettings,
                buttonsTemplate: this.pdfButtons,
                buttonsDirection: 'horizontal',
                ...props
            }
        });
    }

    exportPDF() {
        this.settingsService.updateValue(this.gameId, 'pdfConfig', this.pdfConfig);
        this.pdfService.generatePDF(this.force, this.pdfConfig);
    }

    dismiss() {
        this.popoverController.dismiss();
    }

    getFontSizeLabel(value: number) {
        const labels = {
            6: 'GLOBAL.PDF.FONTSIZE_VSMALL',
            8: 'GLOBAL.PDF.FONTSIZE_SMALL',
            10: 'GLOBAL.PDF.FONTSIZE_MED',
            12: 'GLOBAL.PDF.FONTSIZE_LARGE',
            14: 'GLOBAL.PDF.FONTSIZE_VLARGE'
        };
        const label = getValueFromTranslationSync(labels[value]);
        return label;
    }
}
