import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PageLayoutModule } from '../../global';

import { UserAdminPage } from './user-admin.page';
import { UserLookupPage } from './components/users.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PageLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: '',
                component: UserAdminPage
            }
        ])
    ],
    exports: [],
    declarations: [UserAdminPage, UserLookupPage],
    providers: []
})
export class UserAdminModule {}
