import { inject, Injectable } from '@angular/core';
import { Logger } from '@ngrx/data';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, defaultIfEmpty, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { entityChangeDataLoaded, EntityChangeSelector, LocalStorageDataService, NativeStorageService } from '../global/data';
import { ForceSyncService } from '../global/sync/sync.force';
import { HttpClientWithInFlightCache } from '../global/httpClient';
import { ArmyBuilderConfig } from '../global/config';
import { SettingsService } from '../global/settings/service/settings.service';
import { selectRouter } from '../global/army-builder-store.module';
import { DataLibrary } from '../global/data-library';

import { FORCE_ACTIONS } from './state/actions';
import { selectForces } from './state/selectors';
import { removeSharedForce } from './shared-forces.actions';
import { Faction, Force, Platoon, PointsAdjustment, Unit } from './models';
import { ForceUtils } from './force.utils';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class ForceDataService extends LocalStorageDataService<Force> {
    name = 'forces';
}

let entityChangeSub: Subscription;

@Injectable({ providedIn: 'root' })
export abstract class ForceService {
    abstract dataLibrary: DataLibrary;
    abstract forceUtils: ForceUtils;

    protected forceSyncService = inject(ForceSyncService);
    protected httpClient = inject(HttpClientWithInFlightCache);
    protected config = inject(ArmyBuilderConfig);
    protected settingsService = inject(SettingsService);
    protected logger = inject(Logger);
    protected storage = inject(NativeStorageService);
    protected store = inject(Store);
    ready$ = new BehaviorSubject(false);
    route$: Observable<any> = this.store.select(selectRouter).pipe(distinctUntilChanged());
    selectedPlatoonId$ = this.route$.pipe(
        map((r) => {
            return parseInt(r?.state.queryParams.platoonId || r?.state.params.platoonId);
        }),
        distinctUntilChanged(),
        shareReplay(1)
    );
    selectedPlatoonId = toSignal(this.selectedPlatoonId$);

    // getAll$ = of(0).pipe(
    //     map(() => this.name),
    //     switchMap((name) =>
    //         from(
    //             this.storage.getItem(name, []).then((entities: T[]) => {
    //                 this.entities = entities;
    //                 return entities;
    //             })
    //         )
    //     ),
    //     distinctUntilChanged(),
    //     shareReplay(1)
    // );

    postProcessCache: { [forceId: string]: Force } = {};

    forceId$: Observable<any> = this.route$.pipe(
        map((r) => r?.state?.params.forceId),
        distinctUntilChanged(),
        shareReplay(1)
    );

    forces$: Observable<Force[]> = this.ready$.pipe(
        filter((ready) => !!ready),
        switchMap(() => this.settingsService.loggedIn$),
        filter((l) => !!l),
        switchMap(() =>
            combineLatest([this.store.select(selectForces).pipe(distinctUntilChanged()), this.dataLibrary.unitTemplates$, this.forceId$])
        ),
        map(([forces, unitTemplates, selectedForceId]): Force[] =>
            forces
                .filter((f) => f.gameId === this.gameId)
                .map((force) => this.forceUtils.preProcessForce(force, unitTemplates, selectedForceId))
        ),
        switchMap(
            (forces): Observable<Force[]> =>
                this.forceSyncService.entityChangeCache$.pipe(
                    tap((entityChangeCache) => console.log('!!! 1.2.0', entityChangeCache)),
                    debounceTime(50), // Makes a significant difference to the CPU usage when doing basically anything, by throttling the calls to processForce
                    tap((entityChangeCache) => console.log('!!! 1.2.1', entityChangeCache)),
                    switchMap((entityChangeCache) => {
                        return Promise.all(
                            forces.map(async (f: any) => {
                                const state = entityChangeCache.find((x) => x.forceId === f.id)?.state;
                                if (state !== 'Dirty' && Object.keys(this.postProcessCache).includes(f.id)) {
                                    return {
                                        ...this.postProcessCache[f.id],
                                        selected: f.selected
                                    };
                                }

                                let processedForce = await this.forceUtils.processForce(f);
                                let postProcessedForce = this.forceUtils.postProcessForce(processedForce);
                                console.log({ processedForce });
                                this.postProcessCache[f.id] = postProcessedForce;
                                return postProcessedForce;
                            })
                        );
                    })
                )
        ),
        defaultIfEmpty([]),
        shareReplay(1)
    ) as Observable<Force[]>;

    unitId$: Observable<any> = this.route$.pipe(
        map((r) => r?.state.params.unitId),
        distinctUntilChanged()
    );

    shared$ = this.route$.pipe(
        map((r) => !!r?.state?.data?.shared),
        distinctUntilChanged(),
        shareReplay(1)
    );

    force$: Observable<Force> = combineLatest([
        this.forceId$,
        this.shared$
        // this.dataLibrary.unitTemplates$
    ]).pipe(
        switchMap(([forceId, shared]) => {
            return this.dataLibrary.unitTemplates$.pipe(map((ut) => [forceId, shared, ut]));
        }),
        switchMap(([forceId, shared, unitTemplates]) => {
            if (shared) {
                return this.forceUtils.getForceFromServer(forceId).pipe(
                    map((f) => this.forceUtils.preProcessForce(f, unitTemplates, f.id)),
                    switchMap((f) => this.forceUtils.processForce(f)),
                    map((f) => this.forceUtils.postProcessForce(f))
                );
            }

            return this.forces$.pipe(
                map((forces) => {
                    const force = forces.find((f) => f.id === forceId);
                    return force;
                })
            );
        }),
        catchError((err) => {
            return of(null); // This catches the error thrown by the shared force not being found
        }),
        filter((f) => !!f),
        distinctUntilChanged(),
        shareReplay(1)
    );

    units$: Observable<any> = this.force$.pipe(
        filter((f) => !!f),
        map((force) => force.units),
        distinctUntilChanged(),
        defaultIfEmpty([])
    );

    unit$: Observable<any> = combineLatest([this.force$, this.unitId$]).pipe(
        map((results) => {
            const force: Force = results[0];
            const unitId: string = results[1];

            const unit = force.units.find((u) => u.id === unitId);
            return unit;
        }),
        filter((f) => !!f),
        distinctUntilChanged()
    );

    options$: Observable<any> = this.unit$.pipe(
        map((unit) => unit.options),
        distinctUntilChanged()
    );

    abstract gameId: string;
    gameId$: Observable<string> = this.store.select((s: any) => s?.router?.state?.data?.gameId).pipe(distinctUntilChanged());

    copyForce(force: Force) {
        const newForce = {
            ...force,
            id: null,
            shareViaURL: false,
            sharedWith: [],
            name: force.name + ' Copy'
        };
        return this.add(newForce);
    }

    saveSharedForce(force: Force) {
        return this.copyForce({ ...force });
    }

    removeShare(forceId: string, gameId: string) {
        const url = `${this.config.apiBaseUrl}/userData/forces/shared/${gameId}/${forceId}`;
        return this.httpClient.delete(url).subscribe(() => {
            this.store.dispatch(removeSharedForce({ forceId, gameId }));
        });
    }

    protected initGame() {}
    init() {
        // N.B. this relies on the ForceService NOT being a singleton, as it allows each
        // module to load its own translations and THEN ready up the service, which
        // leads to each force being processed by that module's ForceUtils, where the
        // translations are needed.
        this.initGame();
        this.ready$.next(true);
        this.store
            .select(selectForces)
            .pipe(debounceTime(1000))
            .subscribe((forces) => {
                this.storage.setItem('forces', forces);
            });
    }

    add(force: Force) {
        this.logger.log('ForceService.add');
        const newId = force.id || ('' + Date.now() + Math.floor(Math.random() * 1000)).toString();

        force = { ...force, id: newId };

        this.store.dispatch(FORCE_ACTIONS.ADD_FORCE({ force }));
        return of(force);
    }

    addUnit(newUnit: Unit, force: Force) {
        return this.update({
            id: force.id,
            units: [...force.units, newUnit]
        });
    }

    deleteUnit(force: Force, unit: Unit) {
        return this.update({
            id: force.id,
            units: force.units.filter((u) => u.id !== unit.id)
        });
    }

    async loadFromStorage() {
        const entityChangeData = await this.storage.getItem('EntityChangeSelector', {});
        const deletedForceIds = Object.entries(entityChangeData?.forces || {})
            .filter((x) => x[1] === 'Deleted')
            .map((x) => x[0]);

        this.store.dispatch(entityChangeDataLoaded(entityChangeData));

        if (!entityChangeSub) {
            console.log('Setting up entityChangeSub');
            entityChangeSub = this.store
                .select(EntityChangeSelector)
                .pipe(debounceTime(500))
                .subscribe((state) => {
                    console.log('EntityChangeSelector', state);
                    this.storage.setItem('EntityChangeSelector', state);
                });
        }

        const entities = await this.storage.getItem('forces', []);
        if (entities.length > 0) {
            // Don't load if the array is empty, since that is the default anyway,
            // and because the sync service could be running, storage may be
            // temporarily empty
            this.store.dispatch(FORCE_ACTIONS.LOAD_ALL_FORCES({ forces: entities.filter((f) => !deletedForceIds.includes(f.id)) }));
        }
    }

    updateUnit(force: Force, unit: Unit) {
        this.logger.log('ForceService.updateUnit');

        const updatedForce: Force = {
            ...force,
            units: force.units.map((u) => {
                if (u.id === unit.id) {
                    return unit;
                }
                return u;
            })
        };

        return this.update(updatedForce);
    }

    update(force: Partial<Force> & { id: string }) {
        this.logger.log('ForceService.update', force.id);

        this.store.dispatch(FORCE_ACTIONS.UPDATE_FORCE({ force }));
    }

    upsert(force: Force) {
        this.logger.log('ForceService.upsert');
        if (!force.id) {
            const newId = force.id || ('' + Date.now() + Math.floor(Math.random() * 1000)).toString();
            force = {
                ...force,
                id: newId
            };
        }
        this.store.dispatch(FORCE_ACTIONS.UPSERT_FORCE({ force }));
    }

    delete(forceId: string) {
        this.logger.log('ForceService.delete');
        const deletedForceId$ = this.forces$.pipe(
            take(1),
            map((forces) => {
                const force = forces.find((f) => f.id === forceId);
                const updatedAt = force.updatedAt;

                this.store.dispatch(FORCE_ACTIONS.DELETE_FORCE({ id: forceId, updatedAt }));

                return forceId;
            })
        );

        deletedForceId$.subscribe((forceId) => {
            console.log('Force deleted: ' + forceId);
        });

        return deletedForceId$;
    }

    addPointsAdjustmentToUnit(forceId: string, unitId: string, pointsAdjustment: PointsAdjustment) {
        this.store.dispatch(FORCE_ACTIONS.ADD_POINTS_ADJUSTMENT({ forceId, unitId, pointsAdjustment }));
    }

    removePointsAdjustmentFromUnit(forceId: string, unitId: string, index: number) {
        this.store.dispatch(FORCE_ACTIONS.REMOVE_POINTS_ADJUSTMENT({ forceId, unitId, index }));
    }

    deletePlatoon(forceId, platoonId) {
        const action = FORCE_ACTIONS.DELETE_PLATOON({ forceId, platoonId });
        this.store.dispatch(action);
        return action;
    }

    duplicatePlatoon(forceId, platoonId) {
        this.store.dispatch(FORCE_ACTIONS.DUPLICATE_PLATOON({ forceId, platoonId }));
    }

    addPlatoon(force: Force, platoon: Platoon) {
        this.store.dispatch(FORCE_ACTIONS.ADD_PLATOON({ forceId: force.id, platoon }));
    }

    addUnitOption(forceId: string, unitId: string, optionId: number) {
        this.store.dispatch(FORCE_ACTIONS.ADD_UNIT_OPTION({ forceId, unitId, optionId }));
    }

    removeUnitOption(forceId: string, unitId: string, optionId: number) {
        this.store.dispatch(FORCE_ACTIONS.REMOVE_UNIT_OPTION({ forceId, unitId, optionId }));
    }
}
