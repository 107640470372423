import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { UserService } from '../global';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard {
    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    canActivate(
        _router: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userService.login$.pipe(
            debounceTime(1000),
            map((l) => {
                if (!l.user?.roles?.includes('app_admin')) {
                    console.log("Navigating back to home because user isn't an admin");
                    this.router.navigateByUrl('/');
                }
                return true;
            })
        );
    }
}
