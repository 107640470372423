import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { BaseFormControl } from '../base-control';
import { FormDateField } from '../form-field';

@Component({
    selector: 'abs-date-picker',
    template: `
        <div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <div class="form-group" *ngIf="!field.multi">
                <label *ngIf="!field.hideLabel"
                    >{{ field.label || (field.name | formLabel) }}
                    <ion-icon class="info" *ngIf="field.description" name="information-circle" (click)="showDescription($event)"></ion-icon
                ></label>

                <ion-datetime-button presentation="date" datetime="datetime"></ion-datetime-button>

                <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                        <ion-datetime
                            id="datetime"
                            presentation="date"
                            [formControlName]="field.name"
                            [disabled]="field.readOnly"
                        ></ion-datetime>
                    </ng-template>
                </ion-modal>
            </div>
        </div>
    `,
    styles: [
        `
            ion-icon {
                align-self: center;
                padding-left: 8px;
            }

            .horizontal {
                padding-bottom: 4px;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent extends BaseFormControl {
    field: FormDateField;

    get formArray() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    get formControl() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormControl;
    }
}
